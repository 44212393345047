* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: #f0f0f0;
    color: #29292e;
}

body, input, button, select, textarea{
    font: 400 16px 'Roboto', sans-serif;
}
